<template>
  <BaseDraggableModal
      name="base_document_report"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="base_document_report">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
          <v-btn size="small" @click="closeModal('base_document_report')">
            <v-icon icon="mdi-close" size="x-large"/>
          </v-btn>
          <v-toolbar-title>{{ record.name }}</v-toolbar-title>
          <v-spacer/>
          <v-btn size="small" @click="downloadReport">
            <v-icon icon="mdi-download" size="x-large"/>
          </v-btn>
        </v-toolbar>
        </template>
        <template #content>
           <v-card-text class="ml-5 mr-5">
             <v-row>
               <v-col>{{ $store.getters.translate("root_asset_name").toUpperCase() }}:</v-col>
               <v-col>{{ record.root_asset_name }}</v-col>
             </v-row>
             <v-row>
               <v-col>{{ $store.getters.translate("project_name").toUpperCase() }}:</v-col>
               <v-col>{{ record.name }}</v-col>
             </v-row>
             <v-row>
               <v-col>{{ $store.getters.translate("description").toUpperCase() }}:</v-col>
               <v-col>{{ type }} > {{ subtype }}</v-col>
             </v-row>
             <v-row class="mt-0 mb-0">
               <v-col class="pt-0">
                 <v-switch color="primary" v-model="hide_commissioned" v-on:update:model-value="updateStats" :label="$store.getters.translate('hide_commissioned')"/>
               </v-col>
             </v-row>
             <v-row class="mt-0 mb-0">
               <v-col class="pt-0">
                 {{ $store.getters.translate("filter_by_tags").toUpperCase() }}:
                 <v-radio-group v-model="selected_stats_tag" v-on:update:model-value="updateStats" inline>
                   <v-radio color="primary" v-for="(value2, key, index) in all_stats" :value="key" :label="key" :key="index" small/>
                 </v-radio-group>
               </v-col>
             </v-row>
             <v-row class="mt-0 mb-0">
               <v-col class="pt-0">{{ $store.getters.translate('progress') }}: {{ Math.floor((stats_rows.total_commissioned * 100) / stats_rows.total_amount) }}%</v-col>
               <v-col class="pt-0">{{ $store.getters.translate('nr_documents') }}: {{ stats_rows.total_amount }}</v-col>
               <v-col class="pt-0">{{ $store.getters.translate('nr_commissioned') }}: {{ stats_rows.total_commissioned }}</v-col>
             </v-row>
             <v-data-table
                 :headers="headers"
                 :items="documents_rows"
                 :loading="loading"
                 class="mt-5"
                 :show-select="false"
                 v-bind="footer_props">
               <template v-slot:item.name="{ item }">
                 <a @click="$emit('edit', item);">
                   {{ item.name }}
                 </a>
               </template>
               <template v-slot:item.description="{ item }">
                 {{ $lodash.truncate(item.description, {length: 50}) }}
               </template>
               <template v-slot:item.commissioned="{ item }">
                 <v-checkbox v-if="item.commissioned" color="primary" disabled v-model="item.commissioned"/>
                 <v-checkbox v-else color="red" disabled v-model="item.commissioned"/>
               </template>
               <template v-if="type === 'Certificates' && subtype === 'Certificate'" v-slot:item.flange_status="{ item }">
                 <v-autocomplete
                     color="primary"
                     bg-color="white"
                     variant="underlined"
                     v-if="item.flange_status !== 'accepted' && canEditFlangeManagement"
                     v-model="item.flange_status"
                     :return-object="false"
                     item-value="value"
                     item-title="text"
                     :items="flange_statuses"
                     @update:model-value="updateFlangeStatus(item)"/>
                 <span v-else>{{ item.flange_status }}</span>
               </template>
             </v-data-table>
           </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import {useAbility} from "@casl/vue";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = useAbility()
    return { can }
  },
  data() {
    return {
      page: helpFunctions.pages.reports,
      headers: [],
      flange_statuses: [{ value: "", text: "" },
        { value: "disassembled", text: this.$store.getters.translate("disassembled") },
        { value: "assembled", text: this.$store.getters.translate("assembled") },
        { value: "tightened", text: this.$store.getters.translate("tightened") },
        { value: "n_a", text: "N/A" }],
      loading: false,
      record: {},
      type: '',
      subtype: '',
      all_stats: [],
      all_documents: [],
      stats_rows: [],
      documents_rows: [],
      selected_stats_tag: "with_tags",
      hide_commissioned: false,
      footer_props: helpFunctions.footer_props,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(item) {
      this.record = item;
      this.openModal('base_document_report');
      this.headers = this.getHeaders();
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/projects/project-stats-by-type/" + this.record.id, { type: this.type, subtype: this.subtype })
          .then((response) => {
            this.loading = false;
            this.all_stats = response.data.counters;
            this.all_documents = response.data.documents;
            this.selected_stats_tag = "with_tags";
            this.stats_rows = this.all_stats[this.selected_stats_tag];
            this.documents_rows = [];
            this.hide_commissioned = false;
            this.documents_rows = [...this.all_documents[this.selected_stats_tag]['not_commissioned'],
              ...this.all_documents[this.selected_stats_tag]['commissioned']];
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    downloadReport() {
      this.loading = true;
      var report_tags = "";
      if(this.selected_stats_tag === "with_tags" || this.selected_stats_tag === "all") {
        report_tags = this.record.tags.join(", ");
      }
      else {
        report_tags = this.selected_stats_tag;
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-report", {
            project: this.record,
            stats: this.documents_rows,
            report_tags: report_tags,
            type: 'static-report',
            document_type: this.type,
            document_subtype: this.subtype
          }, {
            responseType: "blob",
          }).then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);

            var project_name = this.record.name.replaceAll('"', '');
            project_name = project_name.replaceAll("'", "");
            project_name = project_name.replaceAll('\\', ' ');
            project_name = project_name.replaceAll('/', ' ');
            project_name = project_name.replaceAll('(', ' ');
            project_name = project_name.replaceAll(')', ' ');
            project_name = project_name.replaceAll('   ', ' ');
            project_name = project_name.replaceAll('  ', ' ');

            link.download = "Progress report " + this.subtype + " " + project_name + " " + report_tags + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    updateStats() {
      console.log(this.selected_stats_tag);
      this.stats_rows = this.all_stats[this.selected_stats_tag];
      console.log(this.stats_rows);
      this.documents_rows = [];
      console.log(this.hide_commissioned);
      if(this.hide_commissioned) {
        this.documents_rows = this.all_documents[this.selected_stats_tag]['not_commissioned'];
      }
      else {
        this.documents_rows = [...this.all_documents[this.selected_stats_tag]['not_commissioned'],
          ...this.all_documents[this.selected_stats_tag]['commissioned']];
      }
      console.log(this.documents_rows);
    },
    updateFlangeStatus(item) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/change-flange-status", { id: item.id, flange_status: item.flange_status })
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("flange_status_changed"));
          }).catch(() => {
            this.$toast.error(this.$store.getters.translate("error"));
            this.loading = false;
          });
    },
    getHeaders() {
      let headers = [
        {
          title: this.$store.getters.translate("commissioned"),
          align: "start",
          sortable: true,
          value: "commissioned",
        },
        {
          title: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          title: this.$store.getters.translate("description"),
          align: "start",
          sortable: true,
          value: "description",
        },
      ];
      if(this.type === "Certificates" && this.subtype === "Certificate") {
        headers.push({
          title: this.$store.getters.translate("flange_status"),
          align: "start",
          sortable: true,
          value: "flange_status",
        });
      }
      return headers;
    },
  },
  computed: {
    canEditFlangeManagement() {
      return this.can("show", this.$createEntity("flangemanagement", { id: 0 }));
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
</style>